import genericService from "./genericService";
import {Company, DocumentNumbering, WorkUnit} from "../types/types";

export const getCompanies = async () => {
    return await genericService.genericGet<Company[]>("/companies/list-companies")
};


export const getWorkUnits = async () => {
    return await genericService.genericGet<WorkUnit[]>("/work-unit")
};

export const getDocumentNumbering = async () => {
    return await genericService.genericGet<DocumentNumbering[]>("/document-numbering")
};
