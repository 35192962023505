import React, {useState} from 'react';
import classes from "../../style.module.css";
import {getPriority, getStatus, getType, isImageFile, truncateText} from "../../../../utils/globalFunctions";
import {Alert, Image} from "antd";
import {File, TaskDetails} from "../../../../types/types";
import TableRow from "./TableRow";
import ListTaskItem from "./ListTaskItem";
import {PaperClipOutlined} from "@ant-design/icons";
import FileUpload from "../../../work-orders/components/FileUpload";


interface ITaskColumnsProps {
    task: TaskDetails
    setIsModalOpen: (open: boolean) => void
}

interface ListProps {
    files: File[];
}

const List: React.FC<ListProps> = ({files}) => {

    // Separate image and non-image files
    const imageFiles = files.filter(file => isImageFile(file.name));
    const nonImageFiles = files.filter(file => !isImageFile(file.name));

    if (files.length > 0) {
        return (
            <div>
                {/* Images Section */}
                {imageFiles.length > 0 && (
                    <div className={classes.imagesContainer}>
                        {imageFiles.map((file: File, index) => (
                            <span key={file.url + index}>
                                <Image
                                    style={{width:"250px", height:"250px", backgroundSize:"cover", borderRadius:"5%"}}
                                    src={file.url}
                                    alt={file.name}
                                />
                            </span>
                        ))}
                    </div>
                )}
<br/>
                {/* Non-Image Files Section */}
                {nonImageFiles.length > 0 && (
                    <div className={classes.filesContainer}>
                        {nonImageFiles.map((file: File, index) => (
                            <span key={file.url + index}>
                                {" "}
                                <PaperClipOutlined />
                                <a
                                    href={file.url}
                                    key={file.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {window.innerWidth <= 480
                                        ? truncateText(file.name, 20, false)
                                        : file.name}
                                </a>
                            </span>
                        ))}
                    </div>
                )}
            </div>
        );
    }
    return <span>Currently no files attached</span>;
};





const TableData: React.FC<ITaskColumnsProps> = ({task, setIsModalOpen}) => {
    const [error, setError] = useState("");

    return (
        <div className={classes.columnContainer}>
            <TableRow title={"Title"} data={task.title}/>
            <TableRow title={"Description"} data={task.text} className={classes.description}/>
            <TableRow title={"Status"} className={classes.tag} data={getStatus(task.status)}/>
            <TableRow title={"Priority"} data={getPriority(task.priority)} extraData={`(${task.priority})`}/>
            <TableRow title={"Type"} data={getType(task.taskKind)} extraData={`(${task.taskKind})`}/>
            <TableRow title={"Assigned users"}
                      data={<ListTaskItem setError={setError}
                                          setIsModalOpen={setIsModalOpen}
                                          taskId={task.id}
                                          data={task.users}
                                          type={"user"}/>}/>
            <TableRow title={"Assigned groups"}
                      data={<ListTaskItem setIsModalOpen={setIsModalOpen}
                                          setError={setError} taskId={task.id}
                                          data={task.groups}
                                          type={"group"}/>}/>
            <TableRow className={classes.company} title={"Company"}
                      data={!task.companyName ? "Currently no assigned company" : task.companyName}/>
            <TableRow className={classes.company} title={"Files"}
                      data={<List files={task.filesAttached}/>}
            />
            <div className={classes.fileContainer}><FileUpload itemId={task.id} fileFolderName={"task"}/></div>
            {error && <Alert type="error" className={classes.alert} message={error}/>}
        </div>
    );
};

export default TableData