import React, {useEffect, useState} from 'react';
import classes from "../styles.module.css";
import TextArea from "antd/es/input/TextArea";
import {Alert, Button} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateWorkOrderNotes} from "../../../services/workOrders";
import {WorkOrderDetails} from "../../../types/types";

interface NotesProps {
    item: WorkOrderDetails;
}

const Notes: React.FC<NotesProps> = ({item}) => {

    useEffect(() => {
        setNotes(item.notes)
    }, [item]);

    const [error, setError] = useState("");
    const [notes, setNotes] = useState(item.notes);
    const queryClient = useQueryClient()


    const mutation = useMutation<any, any, any>({
        mutationFn: () => updateWorkOrderNotes(item.id, notes),
        onSettled: () => {
            //@ts-ignore
            queryClient.invalidateQueries(['workOrderDetails', item.id]).catch(console.error);
            setError("");
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const handleSaveNotes = () => {
        mutation.mutate({
            id: item.id,
            notes: notes
        });
    }


    return (
        <>
            <h2>Notes:</h2>
            <div className={classes.notesContainer}>
                <TextArea onChange={(e) => setNotes(e.target.value)} autoSize={true} value={notes}/>
                <Button type={"primary"} onClick={handleSaveNotes}>Save notes</Button>
            </div>
            <br/>
            {error && <Alert type="error" message={error} className={classes.errorText}/>}
        </>
    )
        ;
};

export default Notes;