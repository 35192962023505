import React from 'react';
import classes from "../../tasks/style.module.css";
import {Card, Collapse, Timeline} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import TaskHistoryItem from "../../tasks/TaskDetailsPage/TaskHistoryItem";
import {useQuery} from "@tanstack/react-query";
import {getWorkOrderLogs} from "../../../services/workOrders";
import {useParams} from "react-router-dom";

const LogsCard = () => {

    const params = useParams()

    const {data: workOrderLogs} = useQuery({
        queryFn: () => getWorkOrderLogs(params?.id ?? ""),
        queryKey: ["workOrderHistory", params.id],
    })

    const items = workOrderLogs && workOrderLogs.length > 0 ?
        workOrderLogs.map((log, index) => ({
            children: <TaskHistoryItem key={index} log={log}/>
        })) :
        [{
            children: 'Currently no history for this work order'
        }];

    return (
        <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2>Work order history <HistoryOutlined/></h2>} key="1">
                <Card className={classes.taskHistoryBody}>
                    <div className={classes.scrollerDiv}>
                        {workOrderLogs && workOrderLogs.length > 0 ? (
                            <Timeline
                                items={[...items]}
                            />
                        ) : (
                            <div>Currently no history for this work order</div>
                        )}
                    </div>
                </Card>
            </Collapse.Panel>
        </Collapse>
    );
};

export default LogsCard;