import React from 'react';
import {Tabs, TabsProps} from "antd";
import classes from "./style.module.css"
import {
    NumberOutlined,
    ShopOutlined,
    TeamOutlined,
    TruckOutlined,
    UserOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import UserTable from "./components/UserTable";
import PersonTable from "./components/PersonTable";
import GroupsTable from "./components/GroupsTable";
import CompaniesTable from "./components/CompaniesTable";
import WorkUnitTable from "./components/WorkUnitTable";
import DocumentNumberingTable from "./components/DocumentNumberingTable";

const InfoPage = () => {


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Users',
            children: <UserTable/>,
            icon: <UserOutlined/>,
        },
        {
            key: '2',
            label: 'Persons',
            children: <PersonTable/>,
            icon: <UserSwitchOutlined/>,
        },
        {
            key: '3',
            label: 'Groups',
            children: <GroupsTable/>,
            icon: <TeamOutlined/>,
        },
        {
            key: '4',
            label: 'Companies',
            children: <CompaniesTable/>,
            icon: <ShopOutlined/>,
        },
        {
            key: '5',
            label: 'Work units',
            children: <WorkUnitTable/>,
            icon: <TruckOutlined />,
        },
        {
            key: '6',
            label: 'Numbering systems',
            children: <DocumentNumberingTable/>,
            icon: <NumberOutlined/>,
        },
    ];

    return (
        <div className={classes.tableContainer}>
            <Tabs defaultActiveKey="1" items={items}/>
        </div>
    );
};

export default InfoPage;