import genericService from "./genericService";
import {TaskLog, WorkOrder, WorkOrderDetails} from "../types/types";
import getAxiosInstance from "./axiosInstance";
import {FormProduct} from "../types/inputs";

interface IAssignRequestBody {
    groupsId: string,
    userIds: string
}


interface ProductsSchema {
    products: FormProduct[
        ],
    workOrderId: string
}

const client = getAxiosInstance();


export const getMyWorkOrders = async () => {
    return await genericService.genericGet<WorkOrder[]>("/workorder")
};

export const getWorkOrderDetails = async (id: string) => {
    return await genericService.genericGet<WorkOrderDetails>(`/workorder/${id}`)
};

export const getWorkOrderLogs = async (id: string) => {
    return await genericService.genericGet<TaskLog[]>(`/workorder/logs/${id}`)
};

export const getProductInstalledByWorkOrder = async (id: string) => {
    return await genericService.genericGet<any>(`/workorder/product_installed/${id}`)
};


export const deleteProductsInstalled = async (workOrderId: string, itemNumber: string) => {
    try {
        const res = await client.delete(`/workorder/product_installed`, {
            data: { workOrderId, itemNumber }  // Adding request body to the DELETE request
        });
        return res.data;
    } catch (error: string | any) {
        throw new Error(error.response?.data?.message || "An error occurred");
    }
};

export const assignProductsInstalled = async (id:string, products: FormProduct[]) => {

    const formattedData = products.map((i) => ({...i, quantity: Number(i.quantity) ?? null}))

    const updatedProducts = formattedData.map(({ productName, units, createdDate, primary, updatedDate, id, ...rest }) => rest);

    return await genericService.genericPut<ProductsSchema,any>(
        `/workorder/product_installed`,
        {
            products: updatedProducts as any,
            workOrderId: id
        }
    )
};

export const updateWorkOrderNotes = async (id: string, notes: any) => {
    return await genericService.genericPut<any, any>(
        `/workorder/${id}/notes`,
        {
            notes: notes
        }
    )
};


export const updateWorkOrder = async (id: string, template: any) => {
    return await genericService.genericPut<any, any>(
        `/workorder/${id}`,
        {
            order_template: template
        }
    )
};

export const assignUsersGroupsWorkOrder = async (id: string, userIds: string[], groupsId: string[]) => {

    const reqBody: any = {}

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupsId.length > 0) {
        reqBody['groupsId'] = groupsId;
    }

    return await genericService.genericPut<IAssignRequestBody, WorkOrder>(
        `/workorder/assign-users-groups/${id}`,
        reqBody
    )
};

export const deleteWorkOrder = async (id:string) => {

    try {
        const res = await client.delete(`/workorder/${id}`);
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};

export const createWorkOrder = async (template: any) => {

    return await genericService.genericPost<any, any>(
        `/workorder`,
        {
            order_template: template,
            documentNumberingId: "69b55cd5-b1d5-4370-bee1-1d7b08e43c11",
            typeIncrement: "workOrder",
        }
    )
};






